import { EventEmitterAsync } from "../../../lib/web/core/event-emmiter-async";
import { Injectable } from "../../../lib/web/reflection/injectable";
import { MediaPlayerComponent } from "../components/organisms/media-player/media-player.component";

export type MediaType = 'capsule' | 'trailer' | 'video';

export interface MediaOptions {
    type: MediaType;
    capsuleId?: number;
    collectionId?: number;
    image?: string;
    currentTime?: number;
    owner?: {
        pathname: string;
        target: string;
    },
    video?: {
        id: string,
        title: string,
    },
    controls?: {
        miniPlayer?: 'enable' | 'disable';
        enableFullScreen?: boolean;
    }
}

@Injectable({ type: 'singleton' })
export class MediaPlayerService {

    private _mediaPlayer: MediaPlayerComponent = null;
    private _eventEmmiter: EventEmitterAsync = new EventEmitterAsync();

    public setMediaPlayer(mediaPlayer: MediaPlayerComponent): void {
        this._mediaPlayer = mediaPlayer;
    }

    public loadMedia(media: MediaOptions): void {
        this._mediaPlayer.load(media);
    }

    public miniPlayerActivated(): void {
        this._eventEmmiter.emit('mini-player-activated');
    }

    public miniPlayerDeactivated(): void {
        this._eventEmmiter.emit('mini-player-deactivated');
    }

    public closePlayer(): void {
        this._mediaPlayer?.close();
    }

    public playerClosed(): void {
        this._eventEmmiter.emit('player-closed');
    }

    public on(eventName: 'mini-player-activated' | 'mini-player-deactivated' | 'player-closed', callback: (...args: any[]) => Promise<void>, owner: any): void {
        this._eventEmmiter.on(eventName, callback);
    }

    public off(owner: any): void {
        this._eventEmmiter.offOwner(owner);
    }

    public get currentMedia(): MediaOptions {
        return this._mediaPlayer.options;
    }

    public get isMiniPlayerActive(): boolean {
        return this._mediaPlayer.isMiniPlayerActive;
    }
}
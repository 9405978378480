import { bootstrap } from "../../../lib/web/components/bootstrap";
import { ComponentBase } from "../../../lib/web/components/component-base";
import { Injectable } from "../../../lib/web/reflection/injectable";
import { Type } from "../../../lib/web/reflection/types";
import { CookieService } from "../../../lib/web/services/cookie.service";
import { ImageLoaderService } from "../../../lib/web/services/image-loader.service";
import { ScrollService } from "../../../lib/web/services/scroll.service";
import { CheckBoxComponent } from "../components/atoms/check-box/check-box.component";
import { CountryBoxComponent } from "../components/atoms/country-box/country-box.component";
import { CountrySelectorComponent } from "../components/atoms/country-selector/country-selector.component";
import { DateBoxComponent } from "../components/atoms/date-box/date-box.component";
import { ToggleSelectorComponent } from "../components/atoms/toggle-selector/toggle-selector.component";
import { IconComponent } from "../components/atoms/icon/icon.component";
import { ItemSelectorDropdownComponent } from "../components/atoms/item-selector-dropdown/item-selector-dropdown.component";
import { ItemSelectorModalComponent } from "../components/atoms/item-selector-modal/item-selector-modal.component";
import { ItemSelectorComponent } from "../components/atoms/item-selector/item-selector.component";
import { PasswordBoxComponent } from "../components/atoms/password-box/password-box.component";
import { SwitchBoxComponent } from "../components/atoms/switch-box/switch-box.component";
import { TextBoxComponent } from "../components/atoms/text-box/text-box.component";
import { AppMenuComponent } from "../components/molecules/app-menu/app-menu.component";
import { ChangePasswordComponent } from "../components/molecules/change-password/change-password.component";
import { ChangePostalCodeComponent } from "../components/molecules/change-postal-code/change-postal-code.component";
import { ChangeProfileComponent } from "../components/molecules/change-profile/change-profile.component";
import { DropdownMenuComponent } from "../components/molecules/dropdown-menu/dropdown-menu.component";
import { ManageDisciplinesComponent } from "../components/molecules/manage-disciplines/manage-disciplines.component";
import { MobileMenuComponent } from "../components/molecules/mobile-menu/mobile-menu.component";
import { ProfileDataComponent } from "../components/molecules/profile-data/profile-data.component";
import { ProfileMenuComponent } from "../components/molecules/profile-menu/profile-menu.component";
import { SearchFilterComponent } from "../components/molecules/search-filter/search-filter.component";
import { ShareComponent } from "../components/molecules/share/share.component";
import { ErrorModalComponent } from "../components/organisms/error-modal/error-modal.component";
import { FooterComponent } from "../components/organisms/footer/footer.component";
import { GridComponent } from "../components/organisms/grid/grid.component";
import { HeaderComponent } from "../components/organisms/header/header.component";
import { ProfileComponent } from "../components/organisms/profile/profile.component";
import { RowComponent } from "../components/organisms/row/row.component";
import { SliderComponent } from "../components/organisms/slider/slider.component";
import { CapsuleComponent } from "../components/pages/capsule/capsule.component";
import { CollectionComponent } from "../components/pages/collection/collection.component";
import { DisciplineComponent } from "../components/pages/discipline/discipline.component";
import { EnvSignInComponent } from "../components/pages/env-sign-in/env-sign-in.component";
import { FirstAccessComponent } from "../components/pages/first-access/first-access.component";
import { ForgotPasswordComponent } from "../components/pages/forgot-password/forgot-password.component";
import { HelpComponent } from "../components/pages/help/help.component";
import { LastReleasesComponent } from "../components/pages/last-releases/last-releases.component";
import { LayoutComponent } from "../components/pages/layout/layout.component";
import { ListViewComponent } from "../components/pages/list-view/list-view.component";
import { RegisterComponent } from "../components/organisms/register/register.component";
import { RenewPasswordComponent } from "../components/pages/renew-password/renew-password.component";
import { SearchComponent } from "../components/pages/search/search.component";
import { SignInComponent } from "../components/pages/sign-in/sign-in.component";
import { VerifyAccountComponent } from "../components/pages/verify-account/verify-account.component";
import { WelcomeComponent } from "../components/pages/welcome/welcome.component";
import { ApiService } from "./api.service";
import { RouterService } from "./router.service";
import { SessionService } from "./session.service";
import { ShareService } from "./share.service";
// import { SearchSuggestionsComponent } from "../components/molecules/search-suggestions/search-suggestions.component";
import { SearchSuggestionsComponent } from "../components/organisms/search-suggestions/search-suggestions.component";
import { FavoritesComponent } from "../components/pages/favorites/favorites.component";
import { TestPlayerComponent } from "../components/pages/test-player/test-player.component";
import { PlayerComponent } from "../components/molecules/player/player.component";
import { VolumneSelectorComponent } from "../components/atoms/volume-selector/volume-selector.component";
import { QualityMenuComponent } from "../components/atoms/quality-menu/quality-menu.component";
import { TimeLineComponent } from "../components/atoms/time-line/time-line.component";
import { AudioMenuComponent } from "../components/atoms/audio-menu/audio-menu.component";
import { UserWatchingHistoryService } from "./user-watching-history.service";
import { TimerComponent } from "../components/atoms/timer/timer.component";
import { CountdownButtonComponent } from "../components/atoms/countdown-button/countdown-button.component";
import { TooltipService } from "./tooltip.service";
import { EnvCodeComponent } from "../components/pages/env-code/env-code.component";
import { PartnerLandingComponent } from "../components/pages/partner-landing/partner-landing.component";
import { TabComponent } from "../components/atoms/tab/tab.component";
import { SeasonSelectorComponent } from "../components/organisms/season-selector/season-selector.component";
import { AboutOldComponent } from "../components/pages/about-old/about-old.component";
import { LocalStorageService } from "../../../lib/web/services/local-storage.service";
import { MiniPlayerMenuComponent } from "../components/atoms/mini-player-menu/mini-player-menu.component";
import { MediaPlayerComponent } from "../components/organisms/media-player/media-player.component";
import { AppDownloadComponent } from "../components/molecules/app-download/app-download.component";
import { LogGroup } from "../../../lib/web/services/client-log.service";
import { MobileDropdownMenuComponent } from "../components/molecules/mobile-dropdown-menu/mobile-dropdown-menu.component";
import { PlayerSegmentsComponent } from "../components/molecules/player-segments/player-segments.component";
import { CapsuleSegmentItemComponent } from "../components/atoms/capsule-segment-item/capsule-segment-item.component";
import { FeaturedComponent } from "../components/organisms/featured/featured.component";
import { BannerComponent } from "../components/organisms/banner/banner.component";
import { BlockPageComponent } from "../components/pages/block-page/block-page.component";
import { CarrouselComponent } from "../components/organisms/carrousel/carrousel.component";
import { FaqsComponent } from "../components/organisms/faqs/faqs.component";
import { LinkDeviceComponent } from "../components/pages/link-device/link-device.component";
import { ModalComponent } from "../components/organisms/modal/modal.component";
import { PlaybackRateMenuComponent } from "../components/atoms/playbackrate-menu/playbackrate-menu.component";


const COMPONENT_TYPES: Type<ComponentBase<any>>[] = [
    HeaderComponent,
    FooterComponent,
    MediaPlayerComponent,
    RowComponent,
    SliderComponent,
    GridComponent,
    TextBoxComponent,
    PasswordBoxComponent,
    DateBoxComponent,
    CheckBoxComponent,
    SwitchBoxComponent,
    IconComponent,
    CountryBoxComponent,
    CountrySelectorComponent,
    ProfileComponent,
    SignInComponent,
    RegisterComponent,
    VerifyAccountComponent,
    ForgotPasswordComponent,
    RenewPasswordComponent,
    FirstAccessComponent,
    ErrorModalComponent,
    ProfileMenuComponent,
    ProfileDataComponent,
    LayoutComponent,
    ChangePasswordComponent,
    ChangeProfileComponent,
    ChangePostalCodeComponent,
    AppMenuComponent,
    DropdownMenuComponent,
    MobileMenuComponent,
    MobileDropdownMenuComponent,
    EnvSignInComponent,
    ListViewComponent,
    LastReleasesComponent,
    ToggleSelectorComponent,
    ManageDisciplinesComponent,
    CollectionComponent,
    DisciplineComponent,
    WelcomeComponent,
    ItemSelectorComponent,
    ItemSelectorDropdownComponent,
    ItemSelectorModalComponent,
    ShareComponent,
    HelpComponent,
    CapsuleComponent,
    SearchComponent,
    SearchFilterComponent,
    SearchSuggestionsComponent,
    FavoritesComponent,
    TestPlayerComponent,
    PlayerComponent,
    VolumneSelectorComponent,
    TimeLineComponent,
    QualityMenuComponent,
    PlaybackRateMenuComponent,
    AudioMenuComponent,
    TimerComponent,
    CountdownButtonComponent,
    EnvCodeComponent,
    PartnerLandingComponent,
    TabComponent,
    SeasonSelectorComponent,
    AboutOldComponent,
    BlockPageComponent,
    MiniPlayerMenuComponent,
    AppDownloadComponent,
    FeaturedComponent,
    PlayerSegmentsComponent,
    CapsuleSegmentItemComponent,
    BannerComponent,
    CarrouselComponent,
    FaqsComponent,
    LinkDeviceComponent,
    ModalComponent,
];

@Injectable({ type: 'singleton' })
export class BootstrapService {

    private _nodes: HTMLElement[] = null;

    public constructor(private _apiService: ApiService, 
        private _cookieService: CookieService, 
        private _imageLoaderService: ImageLoaderService,
        private _scrollService: ScrollService,
        private _sessionService: SessionService,
        private _routerService: RouterService,
        private _shareService: ShareService,
        private _tooltipService: TooltipService,
        private _userWatchingHistoryService: UserWatchingHistoryService,
        private _localStorageService: LocalStorageService) {        

        this._apiService.init();
    }

    public check(): boolean {
        return true;
    }

    public start(mode: 'load' | 'redirect'): void {
        logger.notice(new LogGroup('⚡starting bootstrap ...'));
        logger.notice(`ENV: ${CONFIG.ENV}`);
        logger.notice(`Version: ${document.querySelector<HTMLMetaElement>('meta[property="cxf+:version"')?.content}`);
        logger.closeGroup();
        
        this._scrollService.init();   
        const language: string = this._cookieService.get('language');
        window.USER_LOCALE = LOCALES[language] || LOCALES[CONFIG.DEFAULT_LOCALE];

        this._nodes = bootstrap(
            document,
            mode,
            ...COMPONENT_TYPES
        );

        if (CONFIG.CLIENT_NAVIGATION_MODE == 'render') {
            this._routerService.init(this);
            this._routerService.processAnchors();       
        }     
        this._userWatchingHistoryService.init();
        this._imageLoaderService.init();
        this._sessionService.init();
        this._apiService.checkSession();
        this._shareService.init();
        this._tooltipService.init();
        if (mode == 'load') {
            this._scrollService.register(window);
        }
        this._scrollService.onLoaded();       
    }

    public fragment(): void {
        if (CONFIG.CLIENT_NAVIGATION_MODE == 'render') {
            this._routerService.processAnchors();        
        }
        this._imageLoaderService.init();
    }

    public get nodes(): HTMLElement[] {
        return this._nodes;
    }

    public disposeNodes(): void {
        (this._nodes || []).forEach(node => {
            if (node.componentRef && node.componentRef.ref && node.componentRef.ref.isDisposable) {
                node.componentRef.ref.dispose();
            }
        });
    }
}